/* eslint-disable no-unused-vars */
import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// project imports
const Private = Loadable(
    lazy(() =>
        import('../views/pages/authentication/auth-forms/private/Private'),
    ),
);
const Protected = Loadable(
    lazy(() =>
        import('../views/pages/authentication/auth-forms/private/Protected'),
    ),
);
const FeeSlip = Loadable(lazy(() => import('views/pages/Fees/Fee Slip')));

// student routing
const StudentIdCard = Loadable(
    lazy(() => import('views/pages/Students/IdCard/StudentIdCard')),
);
const StudentAdmitCard = Loadable(
    lazy(() => import('views/pages/Students/AdmitCard/Student AdmitCard')),
);
const Exam = Loadable(
    lazy(() => import('views/pages/Students/AdmitCard/Exam/Exam')),
);
const StudentMessaging = Loadable(
    lazy(() => import('views/pages/Students/Messaging/Student Messaging')),
);
const AddNewSchoolStudent = Loadable(
    lazy(() => import('views/pages/Students/Add New School Student')),
);
const AddNewCoachingStudent = Loadable(
    lazy(() => import('views/pages/Students/Add New Coaching Student')),
);
const AllCoachingStudents = Loadable(
    lazy(() => import('views/pages/Students/All Coaching Students')),
);
const AllSchoolStudents = Loadable(
    lazy(() => import('views/pages/Students/All School Students')),
);

// Employee routing
const EmployeeIdCard = Loadable(
    lazy(() => import('views/pages/Employee/IdCard/EmployeeIdCard')),
);
const EmployeeMessaging = Loadable(
    lazy(() => import('views/pages/Employee/Messaging/Employee Messaging')),
);
const AddNewSchoolEmployee = Loadable(
    lazy(() => import('views/pages/Employee/Add New School Employee')),
);
const AddNewCoachingEmployee = Loadable(
    lazy(() => import('views/pages/Employee/Add New Coaching Employee')),
);
const AllCoachingEmployees = Loadable(
    lazy(() => import('views/pages/Employee/All Coaching Employees')),
);
const AllSchoolEmployees = Loadable(
    lazy(() => import('views/pages/Employee/All School Employees')),
);

// Notice routing
const AddNotice = Loadable(
    lazy(() => import('views/pages/Private/Notice/AddNotice')),
);
const AllNotice = Loadable(
    lazy(() => import('views/pages/Private/Notice/AllNotice')),
);

const CollectFee = Loadable(lazy(() => import('views/pages/Fees/Collect Fee')));

// dashboard routing
const DashboardDefault = Loadable(
    lazy(() => import('views/dashboard/Default/Dashboard')),
);

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const PrivateRoutes = {
    path: '/',
    element: (
        <Private>
            <MainLayout />
        </Private>
    ),
    children: [
        {
            path: 'dashboard',
            element: (
                <Protected>
                    <DashboardDefault />
                </Protected>
            ),
        },
        {
            path: 'student/fees',
            element: (
                <Protected>
                    <CollectFee />
                </Protected>
            ),
        },
        {
            path: 'students/collected-fees-slip',
            element: (
                <Protected>
                    <FeeSlip />
                </Protected>
            ),
        },
        {
            path: 'messaging',
            element: (
                <Protected>
                    <StudentMessaging />
                </Protected>
            ),
        },
        {
            path: 'school',
            children: [
                {
                    path: 'students',
                    children: [
                        {
                            path: 'all-students',
                            element: (
                                <Protected>
                                    <AllSchoolStudents />
                                </Protected>
                            ),
                        },
                        {
                            path: 'add-new',
                            element: (
                                <Protected>
                                    <AddNewSchoolStudent />
                                </Protected>
                            ),
                        },
                    ],
                },
                {
                    path: 'employee',
                    children: [
                        {
                            path: 'all-employee',
                            element: (
                                <Protected>
                                    <AllSchoolEmployees />
                                </Protected>
                            ),
                        },
                        {
                            path: 'add-new',
                            element: (
                                <Protected>
                                    <AddNewSchoolEmployee />
                                </Protected>
                            ),
                        },
                    ],
                },
            ],
        },
        {
            path: 'coaching',
            children: [
                {
                    path: 'students',
                    children: [
                        {
                            path: 'all-students',
                            element: (
                                <Protected>
                                    <AllCoachingStudents />
                                </Protected>
                            ),
                        },
                        {
                            path: 'add-new',
                            element: (
                                <Protected>
                                    <AddNewCoachingStudent />
                                </Protected>
                            ),
                        },
                    ],
                },
                {
                    path: 'employee',
                    children: [
                        {
                            path: 'all-employee',
                            element: (
                                <Protected>
                                    <AllCoachingEmployees />
                                </Protected>
                            ),
                        },
                        {
                            path: 'add-new',
                            element: (
                                <Protected>
                                    <AddNewCoachingEmployee />
                                </Protected>
                            ),
                        },
                    ],
                },
            ],
        },

        // This is for the admin panel
        {
            path: 'admin',
            children: [
                {
                    path: 'notice',
                    children: [
                        {
                            path: 'add',
                            element: <AddNotice />,
                        },
                        {
                            path: 'all',
                            element: <AllNotice />,
                        },
                    ],
                },
            ],
        },
        {
            path: 'print',
            children: [
                {
                    path: 'students',
                    children: [
                        {
                            path: 'id-card',
                            element: <StudentIdCard />,
                        },
                        {
                            path: 'admit-card',
                            element: <StudentAdmitCard />,
                        },
                        {
                            path: 'exam',
                            element: <Exam />,
                        },
                    ],
                },
                {
                    path: 'employee',
                    children: [
                        {
                            path: 'id-card',
                            element: <EmployeeIdCard />,
                        },
                        // {
                        //     path: 'admit-card',
                        //     element: <StudentAdmitCard />
                        // }
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <DashboardDefault />,
        },
    ],
};

export default PrivateRoutes;
