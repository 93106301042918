// assets
import { IconBrandChrome, IconHelp, IconAd2, IconPrinter } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconAd2 };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const Print = {
    id: 'Students',
    // title: 'Other',
    type: 'group',
    children: [
        {
            id: 'all-students',
            title: 'Print Section',
            type: 'collapse',
            icon: IconPrinter,
            children: [
                {
                    id: 'all-students',
                    title: 'Student',
                    type: 'collapse',
                    icon: IconPrinter,
                    children: [
                        {
                            id: 'student id card',
                            title: 'ID Cards',
                            type: 'item',
                            url: 'print/students/id-card',
                            icon: icons.IconId,
                            breadcrumbs: false,
                        },
                        {
                            id: 'student admit card',
                            title: 'Admit Cards',
                            type: 'item',
                            url: 'print/students/admit-card',
                            icon: icons.IconId,
                            breadcrumbs: false,
                        },
                        {
                            id: 'Exam',
                            title: 'Exam',
                            type: 'item',
                            url: 'print/students/exam',
                            icon: icons.IconId,
                            breadcrumbs: false,
                        },
                    ],
                },
                {
                    id: 'all-employee',
                    title: 'Employee',
                    type: 'collapse',
                    icon: IconPrinter,
                    children: [
                        {
                            id: 'employee id card',
                            title: 'ID Cards',
                            type: 'item',
                            url: 'print/employee/id-card',
                            icon: icons.IconId,
                            breadcrumbs: false,
                        },
                        // {
                        //     id: 'employee admit card',
                        //     title: 'Admit Cards',
                        //     type: 'item',
                        //     url: 'print/employee/admit-card',
                        //     icon: icons.IconId,
                        //     breadcrumbs: false
                        // }
                    ],
                },
            ],
        },
        // {
        //     id: 'add-new',
        //     title: 'Add New',
        //     type: 'collapse',
        //     icon: icons.IconUserPlus,
        //     children: [
        //         {
        //             id: 'school add-new',
        //             title: 'Add New In School Student',
        //             type: 'item',
        //             url: '/school/students/add-new',
        //             icon: icons.IconUserPlus,
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'coaching add-new',
        //             title: 'Add New In Coaching Student',
        //             type: 'item',
        //             url: '/coaching/students/add-new',
        //             icon: icons.IconUserPlus,
        //             breadcrumbs: false
        //         }
        //     ]
        // }
        // {
        //     id: 'student-id-card',
        //     title: 'Student Id Card',
        //     type: 'item',
        //     url: '/students/student-id-card',
        //     icon: icons.IconId,
        //     breadcrumbs: false
        // }
    ],
};

export default Print;
