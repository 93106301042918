import React, { useRef, useEffect, useState } from 'react';
import './ScrollingNotices.css';
import axios from '../../../../axios';
import { Box } from '@mui/system';
import { Modal } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

const ScrollingNotices = () => {
    const marqueeRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [selectedNotice, setSelectedNotice] = useState(null); // State to store the selected notice
    const handleClose = () => setOpen(false);

    // modal style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '2px solid tomato',
        boxShadow: 24,
        borderRadius: 4,
        overflow: 'hidden',
        overflowY: 'auto',
        maskImage: 'radial-gradient(transparent, black 0px)',
        // p: '0 2rem',
    };

    useEffect(() => {
        const parentElement = marqueeRef.current.parentNode;
        const parentWidth = parentElement.offsetWidth;

        const dynamicCSS = `
            @keyframes marquee {
                0% {
                    transform: translateX(${parentWidth}px);
                }
                100% {
                    transform: translateX(-100%);
                }
            }
        `;

        const styleElement = document.createElement('style');
        styleElement.textContent = dynamicCSS;

        document.head.appendChild(styleElement);

        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    const handleMouseOver = () => {
        marqueeRef.current.style.animationPlayState = 'paused';
        marqueeRef.current.style.cursor = 'pointer'; // Change the cursor to pointer
    };

    const handleMouseOut = () => {
        marqueeRef.current.style.animationPlayState = 'running';
    };

    const [notice, setNotice] = useState([]);

    useEffect(() => {
        axios.get('/admin/notice?limit=5').then((response) => {
            setNotice(response.data);
        });
    }, []);

    const handleNoticeClick = (selectedNotice) => {
        setSelectedNotice(selectedNotice);
        setOpen(true);
    };

    const handleDownload = async (row) => {
        try {
            const response = await fetch(row.noticeUrl);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `chs.edu.bd_${row.noticeTitle}.jpg`; // Set the desired filename
            // Trigger the download
            link.click();
            // Revoke the Blob URL to release resources
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    return (
        <div className="marquee-containerMain">
            <div className="marquee-icon">
                <h2>
                    🔔<span className="latest">LATEST</span> NOTICE
                </h2>
            </div>
            <div
                className="marquee-container"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <Modal open={open} onClose={handleClose}>
                    <Box sx={style}>
                        {selectedNotice && (
                            <Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        padding: '2rem 2rem 0.1rem 2rem',
                                        position: 'sticky',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        bgcolor: 'white',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            paddingBottom: '1rem',
                                        }}
                                    >
                                        <h2>{selectedNotice.noticeTitle}</h2>
                                        <p>{selectedNotice.noticeCategory}</p>

                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<DownloadIcon />}
                                            onClick={() =>
                                                handleDownload(selectedNotice)
                                            }
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                    <hr
                                        style={{
                                            width: '120%',
                                            marginLeft: '-4rem',
                                        }}
                                    />
                                </Box>
                                <img
                                    src={selectedNotice.noticeUrl}
                                    alt="notice"
                                    style={{ width: '100%' }}
                                />

                                {/* Add whatever content you want to display in the modal */}
                            </Box>
                        )}
                    </Box>
                </Modal>
                <div className="marquee" ref={marqueeRef}>
                    {notice?.map((item) => (
                        <strong key={item?.stackIndex}>
                            &nbsp;🔔&nbsp;
                            <button
                                style={{
                                    cursor: 'pointer',
                                    border: 'none',
                                    background: 'none',
                                    color: 'inherit',
                                    padding: '0',
                                    font: 'inherit',
                                    outline: 'inherit',
                                }}
                                className="scroll-notice-link"
                                onClick={() => handleNoticeClick(item)}
                            >
                                <i className="fas fa-bell"></i>
                                {item?.noticeTitle}
                            </button>
                            &nbsp;||&nbsp;
                        </strong>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ScrollingNotices;
